<template>
  <div :class="getClass" class="mb-3 rounded-lg" >
      <slot/>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";

  const props = defineProps({
    panelType: {
      default: 'normal',
      type: String
    },
    panelTheme: {
      default: 'white',
      type: String
    }
  });

  const getClass = computed(() => {

    let classes = 'bg-' + props.panelTheme;

    switch(props.panelTheme) {
      case 'primary':
      case 'secondary':
          classes += ' text-white';
      break;
    }

    if (props.panelType === 'normal') {
        classes += ' p-6'
    } else if (props.panelType === 'large') {
        classes += ' p-8'
    } else {
        classes += ' p-4'
    }

    return classes;
  })
</script>

<style scoped>

</style>
